//DEFINITION DE LA FONCTION DE SUPPR DES DONNEES STOCKEES EN LOCAL
//AVANT CHAQUE NOUVEAU FORMULAIRE,
//SUPPRESSION DE TOUTES LES DONNEES EN SECURESTORAGE DES FORMULAIRES
import * as Sentry from '@sentry/browser'; // Assurez-vous d'avoir installé et configuré Sentry si vous voulez l'utiliser
import { env } from 'constantes/environement';

import secureStorage from 'hooks/secureStorage';
import { useEffect } from 'react';

// SAUF LES DONNEES UTILES DE LA SESSION
export function supprDataSaufUsersession() {
  const clesessionuser = [
    'usertoken_session',
    'user_role',
    'user_info',
    'enquete_courante',
    'alertShown',
    'isAuthenticated',
    'oldCF',
    'codeCF',
    'signature_com_enq',
  ];

  for (let i = 0; i < secureStorage.length; i++) {
    // while (secureStorage.length > 2) {
    const key = secureStorage.key(i);
    if (!clesessionuser.includes(key)) {
      secureStorage.removeItem(key);
      // break;
    }
    // }
  }
}

export function formaterDate(originalDate) {
  // const originalDate = "2023-10-30T14:15:42.503Z";
  const date = new Date(originalDate);

  const day = date.getDate().toString().padStart(2, '0'); // Get the day and format as two digits
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (add 1 because it's zero-based) and format as two digits
  const year = date.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
}


export function formateDate(originalDate) {
  // const originalDate = "2023-10-30T14:15:42.503Z";
  const date = new Date(originalDate);

  const day = date.getDate().toString().padStart(2, '0'); // Get the day and format as two digits
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (add 1 because it's zero-based) and format as two digits
  const year = date.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

export function formaterDate1(originalDate) {
  // const originalDate = "2023-10-30T14:15:42.503Z";
  const date = new Date(originalDate);

  const day = date.getDate().toString().padStart(2, '0'); // Get the day and format as two digits
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (add 1 because it's zero-based) and format as two digits
  const year = date.getFullYear();

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

// export function ExtraireNombres(superficie) {
//   // Créer un objet pour stocker les résultats de la conversion
//   const result = { ha: 0, a: 0, ca: 0 };

//   const sup = superficie && superficie !== "undefined" ? superficie : "0";
//   // Utilisation d'une expression régulière pour extraire le nombre et l'unité
//   const matches = sup.matchAll(/([\d,.]+)\s*(ha|a|ca)/gi); // g pour rechercher toutes les correspondances, i pour ignorer la casse

//   // Conversion des nombres extraits
//   for (const match of matches) {
//     const nombre = parseFloat(match[1].replace(",", ".")); // Convertir le nombre en float

//     // Convertir en fonction de l'unité de mesure
//     switch (
//       match[2].toLowerCase() // Convertir l'unité de mesure en minuscule
//     ) {
//       case "ha":
//         result.ha += Math.floor(nombre); // Ajouter les hectares
//         break;
//       case "a":
//         result.a += Math.floor(nombre); // Ajouter les ares
//         break;
//       case "ca":
//         result.ca += Number(nombre.toFixed(2)); // Ajouter les centiares
//         break;
//     }
//   }

//   // Conversion des unités
//   result.a += Math.floor(result.ca / 100); // 1 a = 0.01 ha
//   result.ca %= 100; // Mise à jour des centiares après la conversion
//   result.ha += Math.floor(result.a / 100); // 1 ha = 100 a
//   result.a %= 100; // Mise à jour des ares après la conversion

//   //console.log("Result", result);
//   return result;
// }

export function ExtraireNombres(superficie) {
  // Créer un objet pour stocker les résultats de la conversion
  const result = { ha: 0, a: 0, ca: 0 };

  const sup = superficie && superficie !== 'undefined' ? superficie : '0';
  // Utilisation d'une expression régulière pour extraire le nombre et l'unité
  const matches = sup.matchAll(/([\d,.]+)\s*(ha|a|ca)/gi); // g pour rechercher toutes les correspondances, i pour ignorer la casse

  // Conversion des nombres extraits
  for (const match of matches) {
    const nombre = parseFloat(match[1].replace(',', '.')); // Convertir le nombre en float

    // Convertir en fonction de l'unité de mesure
    switch (
      match[2].toLowerCase() // Convertir l'unité de mesure en minuscule
    ) {
      case 'ha':
        result.ha += Math.floor(nombre); // Ignorer les décimales pour les hectares
        const decimalHa = nombre - Math.floor(nombre); // Partie décimale pour les hectares
        result.a += Math.floor(decimalHa * 100); // Convertir la partie décimale en ares
        result.ca += Number(
          ((decimalHa * 100 - Math.floor(decimalHa * 100)) * 100).toFixed(2)
        ); // Convertir la partie décimale en centiares
        break;
      case 'a':
        result.a += Math.floor(nombre); // Ignorer les décimales pour les ares
        result.ca += Number(((nombre - Math.floor(nombre)) * 100).toFixed(2)); // Convertir les décimales en centiares
        break;
      case 'ca':
        result.ca += Number(nombre.toFixed(2)); // Arrondir à l'entier le plus proche pour les centiares
        break;
    }
  }

  // Convertir les unités en fonction des relations définies
  result.ha += Math.floor(result.a / 100); // 1 a = 0.01 ha
  result.a %= 100; // Mise à jour des ares après la conversion
  result.a += Math.floor(result.ca / 100); // 1 ca = 0.01 a
  result.ca %= 100; // Mise à jour des centiares après la conversion

  return result;
}

export function superficietotale(ha, a, ca) {
  let hasup = '00 ha';
  let asup = ' 00 a';
  let casup = ' 00 ca';
  if (ha && ha >= 0) hasup = (ha < 10 ? '0' : '') + ha + ' ha';
  if (a && a >= 0) asup = ' ' + (a < 10 ? '0' : '') + a + ' a';
  if (ca && ca >= 0) casup = ' ' + (ca < 10 ? '0' : '') + ca + ' ca';
  let retour = '';
  if (ha === '0' && a === '0' && ca === '0') {
    hasup = '00 ha';
    asup = ' 00 a';
    casup = ' 00 ca';
  }
  retour = hasup + asup + casup;
  return retour;
}

export function compareIgnoreCase(str1, str2) {
  if (!str1 || !str2) {
    return false;
  }
  return str2.toLowerCase().includes(str1.toLowerCase());
}

export function extractenquetenumber(code_enquete) {
  let splitData = code_enquete.split('-');

  let numeroEnquete =
    splitData[splitData.length - 2] + splitData[splitData.length - 1];
  return numeroEnquete;
}

/**
 * Magasin de données pour stoquer les fichiers
 * @param {String} dbName
 * @param {number} dbVersion
 * @param {Array} storeNames
 * @returns
 */

// export function ouvrirBaseDeDonnees(dbName, dbVersion, storeNames) {
//   return new Promise((resolve, reject) => {
//     const request = indexedDB.open(dbName, dbVersion);

//     request.onupgradeneeded = function (event) {
//       const db = event.target.result;
//       for (const storeName of storeNames) {
//         if (!db.objectStoreNames.contains(storeName)) {
//           db.createObjectStore(storeName, { autoIncrement: true });
//         }
//       }
//     };

//     request.onerror = (event) => {
//       reject("Erreur lors de l'ouverture de la base de données IndexedDB.");
//     };

//     request.onsuccess = (event) => {
//       const db = event.target.result;
//       resolve(db);
//     };
//   });
// }

/**
 * Base de données Indexée du navigateur
 * Facile pour la gestion des fichiers
 * @param {indexedDB} BDD
 * @param {String} categorie
 * @param {Array} fichiers
 *
 */
// export function ajouterFichierDataBase(BDD, categorie, fichiers) {
//   //console.log("fichiers", fichiers)
//   //console.log("categorie", categorie)
//   //console.log("BDD", BDD)
//   const transaction = BDD.transaction([categorie], 'readwrite');
//   const store = transaction.objectStore(categorie);

//   // pacourrir le tableau de fichiers et les rajouter un à un à la BDD
//   const addFileAtIndex = (index) => {
//     //console.log("index", index)
//     //console.log("file length", fichiers?.length)
//     if (index < fichiers.length) {
//       const file = fichiers[index];
//       //console.log("iccccc pour les files")
//       //Rajouter un Fichier dans la base de données
//       //utiliser "put" pour ne pas avoir des doublons
//       //add fait la même chose, mais ne gère pas les doublons
//       const ajoutRequest = store.put(file);

//       //console.log("ajoutRequest", ajoutRequest)

//       ajoutRequest.onsuccess = (event) => {
//         //console.log(`File ${index + 1} added to IndexedDB successfully`);
//         addFileAtIndex(index + 1); // Passer au fichier suivant
//       };

//       ajoutRequest.onerror = (event) => {
//         console.error(
//           `Error adding file ${index + 1} to IndexedDB:`,
//           event.target.error
//         );
//       };
//     }
//   };
//   // Démarrer l'ajout des fichiers à partir de l'index 0
//   addFileAtIndex(0);
// }

// export const clearObjectStore = (dbName, dbVersion, storeName)=> {
//   return new Promise((resolve, reject) => {
//     const request = indexedDB.open(dbName, dbVersion);

//     request.onsuccess = (event) => {
//       const db = event.target.result;
//       const transaction = db.transaction(storeName, "readwrite");
//       const objectStore = transaction.objectStore(storeName);

//       const clearRequest = objectStore.clear();

//       clearRequest.onsuccess = () => {
//         //console.log(`The content of ${storeName} has been cleared.`);
//         resolve();
//       };

//       clearRequest.onerror = (event) => {
//         reject(`Error clearing the content of ${storeName}: ${event.target.errorCode}`);
//       };
//     };

//     request.onerror = (event) => {
//       reject(`Error opening database: ${event.target.errorCode}`);
//     };
//   });
// }

export function recupererReferencesFichiers(db, storeName) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.getAll();

    request.onerror = (event) => {
      // //console.log("erreur indexedDB");

      reject(`Erreur lors de la récupération des données depuis ${storeName}.`);
    };

    request.onsuccess = (event) => {
      // //console.log("succes recuperation indexedDB");

      const references = event.target.result.map((entry) => entry.url);
      resolve(references);
    };
  });
}

/**
 * Fonction pour effacer la base de données IndexedDB
 * @param {String} dbName
 */

export function effacerBDD(db, storeNames) {
  // return new Promise((resolve, reject) => {
  //   //console.log("db", db, "storeNames", storeNames)
  //   const transaction = db.transaction(storeNames, "readwrite");
  //   transaction.onerror = (event) => {
  //     reject(`Erreur lors de la création de la transaction.`);
  //   };
  //   transaction.oncomplete = () => {
  //     // Ferme la base de données après la suppression
  //     //db.close();
  //     resolve();
  //   };
  //   storeNames.forEach((storeName) => {
  //     const store = transaction.objectStore(storeName);
  //     store.clear(); // Supprime tous les documents de l'objet de magasin
  //   });
  // });
}

export const commenceParVoyelle = (mot) => {
  const voyelles = ['a', 'e', 'i', 'o', 'u', 'y'];
  return voyelles.includes(mot.charAt(0).toLowerCase());
};

export function chaqueMotCommenceParMajuscule(str) {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

export function getAllFilesFromDB(database, storeName) {
  return new Promise((resolve, reject) => {
    const transaction = database.transaction(storeName, 'readonly');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.getAll();
    const uniqueFiles = new Set(); // Set to store unique files

    request.onsuccess = () => {
      const files = request.result;
      // Filter out duplicates
      const filteredFiles = files.filter((file) => !uniqueFiles.has(file.id)); // Assuming each file has a unique identifier 'id'
      // Add files to the set to track duplicates
      files.forEach((file) => uniqueFiles.add(file.id));

      resolve(filteredFiles);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

export function deleteFileFromDB(database, storeName, fileId) {
  return new Promise((resolve, reject) => {
    const transaction = database.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.delete(fileId);

    request.onsuccess = () => {
      resolve(`File with ID ${fileId} deleted successfully.`);
    };

    request.onerror = (event) => {
      reject(`Error deleting file with ID ${fileId}: ${event.target.error}`);
    };
  });
}

// delete database
function deleteIndexedDBDatabase(dbName) {
  let deleteRequest = indexedDB.deleteDatabase(dbName);

  deleteRequest.onerror = function (event) {
    console.error('Error deleting database:', event.target.errorCode);
  };

  deleteRequest.onsuccess = function (event) {
    //console.log("Database deleted successfully");
  };

  deleteRequest.onblocked = function (event) {
    console.warn('Delete blocked:', event);
  };
}
// deleteIndexedDBDatabase(dbName);


// export function useCustomConsole() {
//   useEffect(() => {
//     const originalError = console.error;

//     console.error = (...args) => {
//       // if (env.NODE_ENV === 'development') {
//         if (1) {
//           // originalError('une erreur survenue');
//         console.log('une erreur survenue')
//         // originalError('Erreur en developpement:', ...args);
//       } 
//       // else if (env.NODE_ENV === 'production')  {
//         // En production ou autre environnement, on peut personnaliser le message
//         // ou envoyer l'erreur à un service de logging
//         // originalError('Erreur en production:', ...args);
//         // console.log('une erreur survenue')


//         // Envoyer l'erreur à un service de logging (exemple avec Sentry)
//         // Sentry.captureException(args[0]);
//       // }
//     };

//     return () => {
//       console.error = originalError;
//     };
//   }, []);
// }


export function useCustomConsole() {
  useEffect(() => {
    const originalError = console.error;

    console.error = (...args) => {
      if (env.NODE_ENV === 'development') {
        console.log('Une erreur survenue');
        originalError('Erreur en développement:', ...args);
      } else if (env.NODE_ENV === 'production') {
        // console.log('Une erreur survenue');
        // originalError('Erreur en production:', ...args);

        // Envoyer l'erreur à un service de logging (exemple avec Sentry)
        Sentry.captureException(args[0]);
      }
    };

    // Capturer les erreurs de throw
    const handleGlobalError = (message, source, lineno, colno, error) => {
      if (env.NODE_ENV === 'development') {
        console.log('Une erreur survenue');
        originalError('Erreur en développement:', error);
      } else if (env.NODE_ENV === 'production') {
        // console.log('Une erreur survenue');
        // originalError('Erreur en production:', error);

        // Envoyer l'erreur à un service de logging (exemple avec Sentry)
        Sentry.captureException(error);
      }
    };

    // Capturer les promesses non tenues
    const handleUnhandledRejection = (event) => {
      if (env.NODE_ENV === 'development') {
        console.log('Une promesse non tenue survenue');
        originalError('Erreur en développement:', event.reason);
      } else if (env.NODE_ENV === 'production') {
        // console.log('Une promesse non tenue survenue');
        // originalError('Erreur en production:', event.reason);

        // Envoyer l'erreur à un service de logging (exemple avec Sentry)
        Sentry.captureException(event.reason);
      }
    };

    window.onerror = handleGlobalError;
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      console.error = originalError;
      window.onerror = null;
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);
}
