import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
import NotFound from 'pages/NotFound';
import { ProtectedRoute } from 'components';

import AppLayout from './AppLayout';
const AdminHomePage = React.lazy(() => import('pages/Admin/AdminHomePage'));
const User = React.lazy(() => import('pages/Admin/AdminHomePage'));
const ErrorBoundary = React.lazy(() => import('components/Error'));

const Resumecontrat = React.lazy(() => import('pages/Contrats/Resumecontrat'));
const AddCF = React.lazy(() => import('pages/Certifications/AddCF'));
const EditCF = React.lazy(() => import('pages/Certifications/EditCF'));
const AddCFInfo = React.lazy(() => import('pages/Certifications/AddCFInfo'));

const PSFCPAMOFORlivrablescf = React.lazy(
  () => import('pages/Certifications/PSFCPAMOFORlivrablescf')
);

const PSFCPAMOFORLivrablesCloture = React.lazy(
  () => import('pages/Clotures/PSFCPAMOFORLivrablesCloture')
);

const ConsolidationAttestationFormationAEFJ = React.lazy(
  () => import('pages/ConsolidationDispositifs/AttestationFormationAEFJ')
);

const ConsolidationAttestationFormationAEFC = React.lazy(
  () => import('pages/ConsolidationDispositifs/AttestationFormationAEFC')
);

const PSFCPAMOFORLivrablesConsolidation = React.lazy(
  () =>
    import('pages/ConsolidationDispositifs/PSFCPAMOFORLivrablesConsolidation')
);

const PSFCPAMOFORLivrablesConsolidationDroits = React.lazy(
  () =>
    import('pages/ConsolidationDroits/PSFCPAMOFORLivrablesConsolidationDroits')
);
const PSFCPAMOFORlivrablescla = React.lazy(
  () => import('pages/Clarifications/PSFCPAMOFORlivrablescla')
);

const PSFCPAMOFORLivrablesSensibilisation = React.lazy(
  () => import('pages/Sensibilisations/PSFCPAMOFORLivrablesSensibilisation')
);

const PSFCPAMOFORLivrablesDelimitation = React.lazy(
  () => import('pages/Delimitations/PSFCPAMOFORLivrablesDelimitation')
);

const PSFCPAMOFORlivrablesDeclarationOuverture = React.lazy(
  () => import('pages/Clarifications/CLA1/AddDeclarationOuverture')
);
const RapportDeroulementCla = React.lazy(
  () => import('pages/Clarifications/CLA1/RapportDeroulement')
);
const Addlettretransmission = React.lazy(
  () => import('pages/Clarifications/CLA1/addlettretransmission')
);
const PSFCPAMOFORENQCF = React.lazy(
  () => import('pages/Certifications/PSFCPAMOFORENQCF')
);
const DemandeEnqCF = React.lazy(
  () => import('pages/Certifications/CF1/DemandeEnqCF')
);
const InfoOPTCF = React.lazy(
  () => import('pages/Certifications/CF1/InfoOPTCF')
);
const PUB = React.lazy(() => import('pages/Certifications/PUB'));
const AddOuverturePub = React.lazy(
  () => import('pages/Certifications/CF20/AddOuverturePub')
);
const AddEnqueteOuverturePub = React.lazy(
  () => import('pages/Certifications/CF20/AddEnqueteOuverturePub')
);
const AddAnnexesOuverturePub = React.lazy(
  () => import('pages/Certifications/CF20/AddAnnexesOuverturePub')
);
const AddCf19 = React.lazy(() => import('pages/Certifications/CF19/AddCf19'));
const CF20 = React.lazy(() => import('pages/Certifications/CF20'));
const AddPVPub = React.lazy(() => import('pages/Certifications/CF9/AddPVPub'));
const AddAnnexesPVPub = React.lazy(
  () => import('pages/Certifications/CF9/AddAnnexesPVPub')
);
const CF9 = React.lazy(() => import('pages/Certifications/CF9'));
const CF8 = React.lazy(() => import('pages/Certifications/CF8'));
const AddAnnoncePub = React.lazy(
  () => import('pages/Certifications/CF8/AddAnnoncePub')
);
const AddAnnexesAnnoncePub = React.lazy(
  () => import('pages/Certifications/CF8/AddAnnexesAnnoncePub')
);
const FicheLitigesCF = React.lazy(
  () => import('pages/Certifications/CF3/FicheLitigesCF')
);
const ListeDesServitudes = React.lazy(
  () => import('pages/Certifications/CF3/ListeDesServitudes')
);
const EtatDesDroitsDeProp = React.lazy(
  () => import('pages/Certifications/CF3/EtatDesDroitsDeProp')
);
const ListeDetenteursDeDroitsCF = React.lazy(
  () => import('pages/Certifications/CF3/ListeDetenteursDeDroitsCF')
);
const PVRDCTemplate = React.lazy(
  () => import('pages/Certifications/Templates/PVRDC')
);
const ListeDesAyantsDroitsCF = React.lazy(
  () => import('pages/Certifications/CF3/ListeDesAyantsDroitsCF')
);
const GestionnaireCF = React.lazy(
  () =>
    import('pages/Certifications/CF3/DesignationGestionnaireCF/GestionnaireCF')
);

const CF19 = React.lazy(() => import('pages/Certifications/CF19'));
const CF11 = React.lazy(() => import('pages/Certifications/CF11'));
const AddAnnexesValidation = React.lazy(
  () => import('pages/Certifications/CF11/AddAnnexesValidation')
);
const AddValidation = React.lazy(
  () => import('pages/Certifications/CF11/AddValidation')
);
const CF10 = React.lazy(() => import('pages/Certifications/CF10'));

const UpdateGestionnaireCF = React.lazy(
  () =>
    import(
      'pages/Certifications/CF3/DesignationGestionnaireCF/GestionnaireCF/update'
    )
);

const AddAnnexesCECPDC = React.lazy(
  () => import('pages/Certifications/CF10/AddAnnexesCECPDC')
);
const AddCECPDC = React.lazy(
  () => import('pages/Certifications/CF10/AddCECPDC')
);
const SignatairesDesignationGestionnaire = React.lazy(
  () =>
    import(
      'pages/Certifications/CF3/DesignationGestionnaireCF/SignatairesDesignationGestionnaire'
    )
);
const CF4 = React.lazy(() => import('pages/Certifications/CF4'));
const AddAnnexesPVCL = React.lazy(
  () => import('pages/Certifications/CF4/AddAnnexesPVCL')
);
const AddPVCL = React.lazy(() => import('pages/Certifications/CF4/AddPVCL'));
const CF12 = React.lazy(() => import('pages/Certifications/CF12'));
const AddAnnexesCLP = React.lazy(
  () => import('pages/Certifications/CF12/AddAnnexesCLP')
);
const AddCLP = React.lazy(() => import('pages/Certifications/CF12/AddCLP'));
const PVRDC = React.lazy(() => import('pages/Certifications/CF3/PVRDC'));
const ListePVRDC = React.lazy(
  () => import('pages/Certifications/CF3/ListePVRDC')
);
const FicheDemographiqueCF = React.lazy(
  () => import('pages/Certifications/CF3/FicheDemographiqueCF')
);
const AutresDeclarationsCF = React.lazy(
  () =>
    import(
      'pages/Certifications/CF3/DeclarationPrsAuditionneesCF/AutresDeclarationsCF'
    )
);
const InfoOccupantsParcelle = React.lazy(
  () => import('pages/Certifications/CF3/InfoOccupantsParcelle')
);
const QuestionnaireCF = React.lazy(
  () => import('pages/Certifications/CF3/QuestionnaireCF')
);
const CF1 = React.lazy(() => import('pages/Certifications/CF1'));
const PSFCPAMOFOREnqPromoContratenqueteCreee = React.lazy(
  () => import('pages/Enquete/PSFCPAMOFOREnqPromoContratenqueteCreee')
);
const PSFCPAMOFORcreerEnqute = React.lazy(
  () => import('pages/Enquete/PSFCPAMOFORcreerEnqute')
);
const PSFCProfil = React.lazy(() => import('pages/Connexion/PSFCProfil'));
const PSFCMenuprofil = React.lazy(
  () => import('pages/Connexion/PSFCMenuprofil')
);

const PSFCPAMOFOREnqPromoContratContratdonationpTenUpdate = React.lazy(
  () =>
    import(
      'pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpTen/update'
    )
);
const PSFCPAMOFOREnqPromoContratContratdonationpTen = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpTen')
);
const PSFCPAMOFOREnqPromoContratContratdonationpNineUpdate = React.lazy(
  () =>
    import(
      'pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpNine/update'
    )
);
const PSFCPAMOFOREnqPromoContratContratdonationpNine = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpNine')
);
const PSFCPAMOFOREnqPromoContratContratdonationpEightUpdate = React.lazy(
  () =>
    import(
      'pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpEight/update'
    )
);
const PSFCPAMOFOREnqPromoContratContratdonationpEight = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpEight')
);
const PSFCPAMOFOREnqPromoContratContratdonationpSevenUpdate = React.lazy(
  () =>
    import(
      'pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpSeven/update'
    )
);
const PSFCPAMOFOREnqPromoContratContratdonationpSeven = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpSeven')
);
const PSFCPAMOFOREnqPromoContratContratdonationpSixUpdate = React.lazy(
  () =>
    import(
      'pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpSix/update'
    )
);
const PSFCPAMOFOREnqPromoContratContratdonationpSix = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpSix')
);
const PSFCPAMOFOREnqPromoContratContratdonationpFiveUpdate = React.lazy(
  () =>
    import(
      'pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpFive/update'
    )
);
const PSFCPAMOFOREnqPromoContratContratdonationpFive = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpFive')
);
const PSFCPAMOFOREnqPromoContratContratdonationpFourUpdate = React.lazy(
  () =>
    import(
      'pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpFour/update'
    )
);
const PSFCPAMOFOREnqPromoContratContratdonationpFour = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpFour')
);
const PSFCPAMOFOREnqPromoContratContratdonationpThreeUpdate = React.lazy(
  () =>
    import(
      'pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpThree/update'
    )
);
const PSFCPAMOFOREnqPromoContratContratdonationpThree = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpThree')
);
const PSFCPAMOFOREnqPromoContratContratdonationpTwo = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpTwo')
);
const PSFCPAMOFOREnqPromoContratContratdonationpTwoUpdate = React.lazy(
  () =>
    import(
      'pages/Contrats/PSFCPAMOFOREnqPromoContratContratdonationpTwo/update'
    )
);
const Addcontratone = React.lazy(() => import('pages/Contrats/Addcontratone'));
const Addcontratoneupdate = React.lazy(
  () => import('pages/Contrats/Addcontratone/update')
);

const listeAnnexesContrat = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFORListeAnnexesContrat')
);
const PSFCPAMOFOREnqPromoContrataddContrat = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFOREnqPromoContrataddContrat')
);
const PSFCPAMOFOREnqPromoContratUpdateContrat = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFOREnqPromoContrataddContrat/update')
);
const PSFCPAMOFOREnqPromoContrat = React.lazy(
  () => import('pages/Contrats/PSFCPAMOFOREnqPromoContrat')
);
const PSFCPAMOFOR = React.lazy(() => import('pages/Enquete/PSFCPAMOFOR'));
const PSFCProjets = React.lazy(() => import('pages/PSFCProjets'));
const ServicesDemande = React.lazy(() => import('pages/NosServices/demandes'));
const FUProjets = React.lazy(() => import('pages/FUProjets'));
const OtherProjets = React.lazy(() => import('pages/OtherProjets'));
const Projets = React.lazy(() => import('pages/Projets'));
const PSFCReinitialisermdp = React.lazy(
  () => import('pages/Connexion/PSFCReinitialisermdp')
);
const PSFCMdpoublie = React.lazy(() => import('pages/Connexion/PSFCMdpoublie'));
const PSFCSeconnecter = React.lazy(
  () => import('pages/Connexion/PSFCSeconnecter')
);
const PSFCSignin = React.lazy(() => import('pages/Connexion/PSFCSignin'));
const PSFCHome = React.lazy(() => import('pages/PSFCHome'));
const Client = React.lazy(() => import('pages/Client'));
const ClientStep1 = React.lazy(() => import('pages/Client/step1.jsx'));
const ClientStep2 = React.lazy(() => import('pages/Client/step2.jsx'));
const ClientStep3 = React.lazy(() => import('pages/Client/step3.jsx'));
const ClientStep4 = React.lazy(() => import('pages/Client/step4.jsx'));
const ExpertHome = React.lazy(() => import('pages/ExpertHome/homeExpert'));
const Contract = React.lazy(() => import('pages/Admin/AdminHomePage'));
const Certificat = React.lazy(() => import('pages/Admin/AdminHomePage'));
const Setting = React.lazy(() => import('pages/Admin/AdminHomePage'));
const Notification = React.lazy(() => import('pages/Admin/AdminHomePage'));
const Corbeille = React.lazy(() => import('pages/Admin/AdminHomePage'));
const Ota = React.lazy(() => import('pages/Admin/AdminHomePage'));

const Contact = React.lazy(() => import('pages/PSFCContact'));

const PSFCPAMOFORmodelepagevalidationcontratspageValidationSix = React.lazy(
  () =>
    import(
      'pages/Validations/PSFCPAMOFORmodelepagevalidationcontratspageValidationSix'
    )
);
const PSFCPAMOFORmodelepagevalidationcontratspageValidation1 = React.lazy(
  () =>
    import(
      'pages/Validations/PSFCPAMOFORmodelepagevalidationcontratspageValidation1'
    )
);
const PSFCPAMOFORmodelepagevalidationcontratspageValidationOne1 = React.lazy(
  () =>
    import(
      'pages/Validations/PSFCPAMOFORmodelepagevalidationcontratspageValidationOne1'
    )
);
const PSFCPAMOFORmodelepagevalidationcontratspageValidationFour1 = React.lazy(
  () =>
    import(
      'pages/Validations/PSFCPAMOFORmodelepagevalidationcontratspageValidationFour1'
    )
);
const PSFCPAMOFORmodelepagevalidationcontratspageValidationFour = React.lazy(
  () =>
    import(
      'pages/Validations/PSFCPAMOFORmodelepagevalidationcontratspageValidationFour'
    )
);
const PSFCPAMOFORmodelepagevalidationcontratspageValidationThree = React.lazy(
  () =>
    import(
      'pages/Validations/PSFCPAMOFORmodelepagevalidationcontratspageValidationThree'
    )
);
const PSFCPAMOFORmodelepagevalidationcontratspageValidationTwo = React.lazy(
  () =>
    import(
      'pages/Validations/PSFCPAMOFORmodelepagevalidationcontratspageValidationTwo'
    )
);
const PSFCPAMOFORmodelepagevalidationcontratspageValidationFive = React.lazy(
  () =>
    import(
      'pages/Validations/PSFCPAMOFORmodelepagevalidationcontratspageValidationFive'
    )
);
const PSFCPAMOFORmodelepagevalidationcontratsRejetCGEDS = React.lazy(
  () =>
    import(
      'pages/Validations/PSFCPAMOFORmodelepagevalidationcontratsRejetCGEDS'
    )
);
const PSFCPAMOFORmodelepagevalidationcontratspageValidationOne = React.lazy(
  () =>
    import(
      'pages/Validations/PSFCPAMOFORmodelepagevalidationcontratspageValidationOne'
    )
);

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <AppLayout>
          <Routes>
            <Route path="/" element={<PSFCHome />} />
            <Route path="/client" element={<Client />} />
            <Route path="/ClientStep1" element={<ClientStep1 />} />
            <Route path="/ClientStep2" element={<ClientStep2 />} />
            <Route path="/ClientStep3" element={<ClientStep3 />} />
            <Route path="/ClientStep4" element={<ClientStep4 />} />
            <Route path="/accueil-expert" element={<ExpertHome />} />
            <Route path="*" element={<NotFound />} />

            <Route
              path="/psfcpamoformodelepagevalidationcontratspagevalidationone"
              element={
                <PSFCPAMOFORmodelepagevalidationcontratspageValidationOne />
              }
            />
            <Route
              path="/psfcpamoformodelepagevalidationcontratsrejetcgeds"
              element={<PSFCPAMOFORmodelepagevalidationcontratsRejetCGEDS />}
            />
            <Route
              path="/psfcpamoformodelepagevalidationcontratspagevalidationfive"
              element={
                <PSFCPAMOFORmodelepagevalidationcontratspageValidationFive />
              }
            />
            <Route
              path="/psfcpamoformodelepagevalidationcontratspagevalidationtwo"
              element={
                <PSFCPAMOFORmodelepagevalidationcontratspageValidationTwo />
              }
            />
            <Route
              path="/psfcpamoformodelepagevalidationcontratspagevalidationthree"
              element={
                <PSFCPAMOFORmodelepagevalidationcontratspageValidationThree />
              }
            />
            <Route
              path="/psfcpamoformodelepagevalidationcontratspagevalidationfour"
              element={
                <PSFCPAMOFORmodelepagevalidationcontratspageValidationFour />
              }
            />
            <Route
              path="/psfcpamoformodelepagevalidationcontratspagevalidationfour1"
              element={
                <PSFCPAMOFORmodelepagevalidationcontratspageValidationFour1 />
              }
            />
            <Route
              path="/psfcpamoformodelepagevalidationcontratspagevalidationthree"
              element={
                <PSFCPAMOFORmodelepagevalidationcontratspageValidationThree />
              }
            />
            <Route
              path="/psfcpamoformodelepagevalidationcontratspagevalidationone1"
              element={
                <PSFCPAMOFORmodelepagevalidationcontratspageValidationOne1 />
              }
            />
            <Route
              path="/psfcpamoformodelepagevalidationcontratspagevalidation1"
              element={
                <PSFCPAMOFORmodelepagevalidationcontratspageValidation1 />
              }
            />
            <Route
              path="/psfcpamoformodelepagevalidationcontratspagevalidationsix"
              element={
                <PSFCPAMOFORmodelepagevalidationcontratspageValidationSix />
              }
            />

            <Route path="/contact" element={<Contact />} />

            <Route path="/AdminHomePage" element={<AdminHomePage />}>
              <Route path="user" element={<User />} />
              <Route path="ota" element={<Ota />} />
              <Route path="contract" element={<Contract />} />
              <Route path="certificat" element={<Certificat />} />
              <Route path="setting" element={<Setting />} />
              <Route path="notification" element={<Notification />} />
              <Route path="corbeille" element={<Corbeille />} />
            </Route>
            <Route
              path="/resumecontrat"
              element={<ProtectedRoute element={Resumecontrat} />}
            />

             <Route
              path="/error"
              element={<ProtectedRoute element={ErrorBoundary} />}
            />

            <Route path="/psfcsignin" element={<PSFCSignin />} />
            <Route path="/psfcseconnecter" element={<PSFCSeconnecter />} />
            <Route path="/psfcmdpoublie" element={<PSFCMdpoublie />} />
            <Route
              path="/psfcreinitialisermdp"
              element={<PSFCReinitialisermdp />}
            />
            <Route path="/psfcmenuprofil" element={<PSFCMenuprofil />} />
            <Route path="/psfcprofil" element={<PSFCProfil />} />
            <Route
              path="/psfcpamoforcreerenqute"
              element={<ProtectedRoute element={PSFCPAMOFORcreerEnqute} />}
            />
            <Route
              path="/psfcpamoforenqpromocontratenquetecreee"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratenqueteCreee}
                />
              }
            />
            <Route
              path="/psfcprojets"
              element={<ProtectedRoute element={PSFCProjets} />}
            />
            <Route
              path="/foncierurbaineprojets"
              element={<ProtectedRoute element={FUProjets} />}
            />
            <Route
              path="/autresprojets"
              element={<ProtectedRoute element={OtherProjets} />}
            />

            <Route
              path="/projets"
              element={<ProtectedRoute element={Projets} />}
            />
            <Route
              path="/service/demande"
              element={<ProtectedRoute element={ServicesDemande} />}
            />

            <Route
              path="/psfcpamofor"
              element={<ProtectedRoute element={PSFCPAMOFOR} />}
            />

            <Route
              path="/psfcpamoforlivrablescla"
              element={<ProtectedRoute element={PSFCPAMOFORlivrablescla} />}
            />

            <Route
              path="/psfcpamoforlivrablessensiblisation"
              element={
                <ProtectedRoute element={PSFCPAMOFORLivrablesSensibilisation} />
              }
            />

            <Route
              path="/psfcpamoforlivrablescloture"
              element={<ProtectedRoute element={PSFCPAMOFORLivrablesCloture} />}
            />

            <Route
              path="/psfcpamoforlivrablesdelimitation"
              element={
                <ProtectedRoute element={PSFCPAMOFORLivrablesDelimitation} />
              }
            />

            <Route
              path="/declarationouverture"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFORlivrablesDeclarationOuverture}
                />
              }
            />

            <Route
              path="/rapport-deroulement"
              element={<ProtectedRoute element={RapportDeroulementCla} />}
            />

            <Route
              path="/psfcpamoforconsolidationdispositifs"
              element={
                <ProtectedRoute element={PSFCPAMOFORLivrablesConsolidation} />
              }
            />

            <Route
              path="/psfcpamoforconsolidationdroits"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFORLivrablesConsolidationDroits}
                />
              }
            />

            <Route
              path="/consodispo/attestationformationaefj"
              element={
                <ProtectedRoute
                  element={ConsolidationAttestationFormationAEFJ}
                />
              }
            />

            <Route
              path="/consodispo/attestationformationaefc"
              element={
                <ProtectedRoute
                  element={ConsolidationAttestationFormationAEFC}
                />
              }
            />

            <Route
              path="/addlettretransmission"
              element={<ProtectedRoute element={Addlettretransmission} />}
            />

            <Route
              path="/psfcpamoforenqpromocontrat"
              element={<ProtectedRoute element={PSFCPAMOFOREnqPromoContrat} />}
            />
            <Route
              path="/psfcpamoforenqpromocontrataddcontrat"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContrataddContrat}
                />
              }
            />

            <Route
              path="/psfcpamoforenqpromocontratupdatecontrat"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratUpdateContrat}
                />
              }
            />
            <Route
              path="/psfcpamoforlivrablescf"
              element={<ProtectedRoute element={PSFCPAMOFORlivrablescf} />}
            />

            <Route
              path="/psfcpamoforenqcf"
              element={<ProtectedRoute element={PSFCPAMOFORENQCF} />}
            />
            <Route path="/addcf" element={<ProtectedRoute element={AddCF} />} />
            <Route
              path="/editcf"
              element={<ProtectedRoute element={EditCF} />}
            />
            {
              <Route
                path="/addcfinfo"
                element={<ProtectedRoute element={AddCFInfo} />}
              />
            }
            <Route
              path="/demandeenqcf"
              element={<ProtectedRoute element={DemandeEnqCF} />}
            />
            <Route
              path="/infooptcf"
              element={<ProtectedRoute element={InfoOPTCF} />}
            />
            <Route
              path="/FicheLitigesCF"
              element={<ProtectedRoute element={FicheLitigesCF} />}
            />
            <Route
              path="/EtatDesDroitsDeProp"
              element={<ProtectedRoute element={EtatDesDroitsDeProp} />}
            />
            <Route
              path="/ListeDetenteursDeDroitsCF"
              element={<ProtectedRoute element={ListeDetenteursDeDroitsCF} />}
            />
            <Route
              path="/ListeDesServitudes"
              element={<ProtectedRoute element={ListeDesServitudes} />}
            />
            <Route path="/pub" element={<ProtectedRoute element={PUB} />} />
            <Route
              path="/addannexesouverturepub"
              element={<ProtectedRoute element={AddAnnexesOuverturePub} />}
            />
            <Route
              path="/addouverturepub"
              element={<ProtectedRoute element={AddOuverturePub} />}
            />
            <Route
              path="/addenqueteouverturepub"
              element={<ProtectedRoute element={AddEnqueteOuverturePub} />}
            />
            <Route
              path="/addannexespvpub"
              element={<ProtectedRoute element={AddAnnexesPVPub} />}
            />
            <Route
              path="/addpvpub"
              element={<ProtectedRoute element={AddPVPub} />}
            />
            <Route
              path="/addcf19"
              element={<ProtectedRoute element={AddCf19} />}
            />
            <Route
              path="/addannexesannoncepub"
              element={<ProtectedRoute element={AddAnnexesAnnoncePub} />}
            />
            <Route
              path="/addannoncepub"
              element={<ProtectedRoute element={AddAnnoncePub} />}
            />
            <Route
              path="/ListeDesAyantsDroitsCF"
              element={<ProtectedRoute element={ListeDesAyantsDroitsCF} />}
            />
            <Route
              path="/GestionnaireCF"
              element={<ProtectedRoute element={GestionnaireCF} />}
            />
            <Route
              path="/updateGestionnaireCF"
              element={<ProtectedRoute element={UpdateGestionnaireCF} />}
            />
            <Route
              path="/uodateGestionnaireCF"
              element={<ProtectedRoute element={UpdateGestionnaireCF} />}
            />
            <Route
              path="/addannexesvalidation"
              element={<ProtectedRoute element={AddAnnexesValidation} />}
            />
            <Route
              path="/addvalidation"
              element={<ProtectedRoute element={AddValidation} />}
            />
            <Route
              path="/addannexescecpdc"
              element={<ProtectedRoute element={AddAnnexesCECPDC} />}
            />
            <Route
              path="/addcecpdc"
              element={<ProtectedRoute element={AddCECPDC} />}
            />
            <Route
              path="/updateGestionnaireCF"
              element={<ProtectedRoute element={UpdateGestionnaireCF} />}
            />
            <Route
              path="/SignatairesDesignationGestionnaire"
              element={
                <ProtectedRoute element={SignatairesDesignationGestionnaire} />
              }
            />
            <Route
              path="/GestionnaireCF"
              element={<ProtectedRoute element={GestionnaireCF} />}
            />
            <Route
              path="/pvrdcTemplate"
              element={<ProtectedRoute element={PVRDCTemplate} />}
            />
            <Route
              path="/addannexespvcl"
              element={<ProtectedRoute element={AddAnnexesPVCL} />}
            />
            <Route
              path="/addannexesclp"
              element={<ProtectedRoute element={AddAnnexesCLP} />}
            />
            <Route path="/cf4" element={<ProtectedRoute element={CF4} />} />
            <Route path="/cf8" element={<ProtectedRoute element={CF8} />} />
            <Route path="/cf9" element={<ProtectedRoute element={CF9} />} />
            <Route path="/cf10" element={<ProtectedRoute element={CF10} />} />
            <Route path="/cf11" element={<ProtectedRoute element={CF11} />} />
            <Route path="/cf12" element={<ProtectedRoute element={CF12} />} />
            <Route path="/cf19" element={<ProtectedRoute element={CF19} />} />
            <Route path="/cf20" element={<ProtectedRoute element={CF20} />} />
            <Route
              path="/addpvcl"
              element={<ProtectedRoute element={AddPVCL} />}
            />
            <Route
              path="/addclp"
              element={<ProtectedRoute element={AddCLP} />}
            />
            <Route path="/pvrdc" element={<ProtectedRoute element={PVRDC} />} />
            <Route
              path="/listepvrdc"
              element={<ProtectedRoute element={ListePVRDC} />}
            />
            <Route
              path="/fichedemographiquecf"
              element={<ProtectedRoute element={FicheDemographiqueCF} />}
            />
            <Route
              path="/AutresDeclarationsCF"
              element={<ProtectedRoute element={AutresDeclarationsCF} />}
            />
            <Route
              path="/InfoOccuppantsParcelle"
              element={<ProtectedRoute element={InfoOccupantsParcelle} />}
            />
            <Route
              path="/QuestionnaireCF"
              element={<ProtectedRoute element={QuestionnaireCF} />}
            />
            <Route
              path="/Addcontratone"
              element={<ProtectedRoute element={Addcontratone} />}
            />
            <Route
              path="/AddcontratoneUpdate"
              element={<ProtectedRoute element={Addcontratoneupdate} />}
            />
            <Route
              path="/psfcpamoforlisteannexescontrat"
              element={<ProtectedRoute element={listeAnnexesContrat} />}
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationptwo"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpTwo}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationptwoupdate"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpTwoUpdate}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpthree"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpThree}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpthreeUpdate"
              element={
                <ProtectedRoute
                  element={
                    PSFCPAMOFOREnqPromoContratContratdonationpThreeUpdate
                  }
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpfourupdate"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpFourUpdate}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpfour"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpFour}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpfive"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpFive}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpfiveupdate"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpFiveUpdate}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpsix"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpSix}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpsixupdate"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpSixUpdate}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpseven"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpSeven}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpsevenupdate"
              element={
                <ProtectedRoute
                  element={
                    PSFCPAMOFOREnqPromoContratContratdonationpSevenUpdate
                  }
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpeight"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpEight}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpeightupdate"
              element={
                <ProtectedRoute
                  element={
                    PSFCPAMOFOREnqPromoContratContratdonationpEightUpdate
                  }
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpnine"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpNine}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpnineupdate"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpNineUpdate}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationpten"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpTen}
                />
              }
            />
            <Route
              path="/psfcpamoforenqpromocontratcontratdonationptenupdate"
              element={
                <ProtectedRoute
                  element={PSFCPAMOFOREnqPromoContratContratdonationpTenUpdate}
                />
              }
            />

            <Route path="/cf1" element={<CF1 />} />
            <Route path="/dhiwise-dashboard" element={<Home />} />
            {/* </Route> */}
          </Routes>
        </AppLayout>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
